//BRAND
export const name = '1BTEK CourseGen';
export const company = '1BTEK LLC';
export const websiteURL = 'https://coursegen.1btek.com';
export const serverURL = 'https://coursegen.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/coursegen-9aaea.appspot.com/o/primo.png?alt=media&token=cf9db8c7-de0b-43ea-91f3-ba5482574a06';
export const razorpayEnabled = true;
export const paypalEnabled = true;
export const stripeEnabled = true;
export const paystackEnabled = true;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = 'week';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 6.32;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 51.09;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The 1BTEK Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Annan Fuseni";
export const profession = 'History Tutor At Bright Star Educational Complex';
export const photoURL = 'https://firebasestorage.googleapis.com/v0/b/coursegen-9aaea.appspot.com/o/9334398.jpg?alt=media&token=776a398b-6d29-4dfd-b312-6a0f563f6ed3';

//PAYPAL
export const paypalPlanIdOne = "P-1EM732768S920784HMWKW3OA";
export const paypalPlanIdTwo = "P-8T744865W27080359MWOCE5Q";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1OTo7CSDXmLtVnVeaHIHxqCj";
export const stripePlanIdTwo = "price_1OTo7eSDXmLtVnVeBbn82U5B";

//PAYSTACK
export const paystackPlanIdOne = "PLN_u8ckxknbcian7p9";
export const paystackPlanIdTwo = "PLN_u8ckxknbcian7p9";
export const amountInZarOne = '113';
export const amountInZarTwo = '915';